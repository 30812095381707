// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuDRfwrRC4qXsEdxIunKq7P8G_1cAS-Bs",
  authDomain: "budcinemas.firebaseapp.com",
  databaseURL: "https://budcinemas.firebaseio.com",
  projectId: "budcinemas",
  storageBucket: "budcinemas.appspot.com",
  messagingSenderId: "828723490701",
  appId: "1:828723490701:web:884ab3d3b9c22a23",
  measurementId: "G-BCW2TZMNQ3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);