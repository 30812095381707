import React from 'react';

function Theater(){
	return (
		<div className="Panel">
			<iframe width="1280" height="720" src="https://www.youtube.com/embed/videoseries?list=PLlRLCKDRk-ThpfEBemvCOqecSRdAkFFeR&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowFullScreen></iframe>
		</div>
	)
}

export default Theater;