import React, {useState, useEffect} from 'react';

function MoviePanel(props){
	const [movie,setMovie] = useState({});
	const [imageURL, setImageURL] = useState("");
	const [directorName, setDirectorName] = useState("");
	
	useEffect(()=>{
		const apiKey = "34470c13786811285ce9cdc33a938415"; 
		fetch(`https://api.themoviedb.org/3/configuration?api_key=${apiKey}`)
			.then(resp=>resp.json())
			.then((data)=>{
				let imgURL = data.images.base_url + "w154";
				setImageURL(imgURL);
				
		})
		
		fetch(`https://api.themoviedb.org/3/movie/${props.movieId}?api_key=${apiKey}&language=en-US`)
			.then(resp=>resp.json())
			.then((data)=>{
				console.log(data);
				setMovie(data);
		})
		
		fetch(`https://api.themoviedb.org/3/movie/${props.movieId}/credits?api_key=${apiKey}`)
			.then(resp=>resp.json())
			.then((data)=>{
				console.log(data);
				let directors = [];
				for(let person in data.crew){
					if(data.crew[person].job==='Director'){
						directors.push(data.crew[person].name);
					}
				}
				
				switch(directors.length){
					case 1:
						setDirectorName(directors[0]);
						break;
					default:
						let lastDir = directors.pop();
						setDirectorName(directors.join(", ") + " & " + lastDir);
						break;
				}
		})
	},movie)
	
	return (
		<div className="Panel MoviePanel">
			<div>
				<img src={imageURL + movie.poster_path} />
			</div>
			<div>
				<h3 className="MoviePanelTitle">{`${movie.title} (${new Date(movie.release_date).getFullYear()})`}</h3>
				<p>Directed by {directorName}</p>
				<p>{movie.genres ? movie.genres[0].name: ""} ⚫ {`${Math.floor(movie.runtime/60)}h ${movie.runtime%60}m`}</p>
				<p className="Description">{movie.overview}</p>
			</div>
				
		</div>
	)
}

export default MoviePanel;