import React from 'react';
import { Link } from "react-router-dom";

function Footer(){
	let linkStyle = {
		textDecoration: "none",
		color: "inherit",
		fontFamily: 'inherit',
		fontSize: '0.8em'
	}
	

	
	return (
		<div>
			<Link to="/theater" style={linkStyle}><p>©2023 Bud Cinemas</p></Link>
		</div>
	)
}

export default Footer;