import React from 'react';
import {db} from '../db_init.js';
import {get, ref, child} from 'firebase/database';
import { Link, useLoaderData } from "react-router-dom";
import './MainPageStyle.css';

function MainPage(){
	const {EventData} = useLoaderData();
		
	let eventIds = Object.keys(EventData).sort((a,b)=>{
		let date2 = new Date(EventData[b].premiereDate);
		let date1 = new Date(EventData[a].premiereDate)
		
		return (date2-date1);
	})
	
	let eventTiles = eventIds.map((item)=> EventTile(item,EventData[item].imgSrc))

	return (
		<div>
			<div className="EventTiles">
				{eventTiles}
			</div>
		</div>
	)

}

function EventTile(eventId, eventTileSrc){
	function handleClick(e){
		//console.log(eventId);
	}
	
	return (
		<div className="EventTile" onClick={handleClick} key={eventId}>
			<Link to={`/events/${eventId}`}> <img src={eventTileSrc} alt={eventId}/> </Link>
		</div>
	)
}

export async function LoadAllEvents(){
    const snapshot = await get(child(ref(db), '/events'));
    let EventData;
    if (snapshot.exists()) {
        EventData = snapshot.val();
    } else {
        console.log("No data available");
        EventData = null;
    }
 
    return {EventData};
}





export default MainPage;