import {db} from '../db_init.js';
import { ref, child, get } from "firebase/database";
import {useLoaderData} from 'react-router-dom';
import './EventPageStyle.css';
import MoviePanel from './MoviePanel';

function EventPage(){
	const {event} = useLoaderData();


			
	let featureList = "";
	let featureListKeys = Object.keys(event.movieId);
	switch(featureListKeys.length){
		case 1:
			featureList = event.movieId[featureListKeys[0]];
			break;
		case 2:
			featureList = `${event.movieId[featureListKeys[0]]} & ${event.movieId[featureListKeys[1]]}`;
			break;
		default:
			for(let i=0; i < featureListKeys.length-1; i++){
				featureList += event.movieId[featureListKeys[i]] + " ,";
			}
			featureList += `${event.movieId[featureListKeys[featureListKeys.length-1]]}`;
	}
	
	let playlistArea;
	if(event.musicPlaylistURL!==undefined) {
		playlistArea = (
			<div >
				<h3>Official playist</h3>
				<div className="MusicWrapper">
					<iframe src={event.musicPlaylistURL} frameBorder="0" allowtransparency="true" height="80px" title={`${event.name} YouTube Playlist`}></iframe>
				</div>
			</div>
		)
	} else {
		playlistArea = null;
	}
	

	let premiereDate = new Date(event.premiereDate).toLocaleDateString();
	
	//let MoviePanels = null;
	let MoviePanels = Object.keys(event.movieId).map(id=><MoviePanel movieId={id} key={id} />);
	return (
		<div className="EventPage">
			<div className="Panel EventPanel">
				<div className="VideoWrapper">
					<iframe src={event.videoPlaylistURL} width="560" height="315" frameborder="0" allowtransparency="true" allow="encrypted-media" title={`${event.name} Spotify Playlist`}></iframe>
				</div>

				<div className="EventDetails" >
					<h2>{event.name}</h2>
					<div >
						<h3>featuring {featureList}</h3>
						<span>Premiered {premiereDate}</span>
					</div>

					<p className="Description" style={{textAlign:"center"}}>{event.description}</p>

					{playlistArea}
				</div>
			</div>
			{MoviePanels}
		</div>
	)
}


export async function loadEventData({ params }) {
	let event = null;
	let eventId = params.id;
	let eventRef = child(ref(db), `events/${eventId}`);

	const snapshot = await get(eventRef);
	if (snapshot.exists()) {
		event = snapshot.val();
	} else {
		console.log("No data available");
	}
	
	return {event};
}

export default EventPage;