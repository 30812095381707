import React from "react";
import TopBar from './TopBar/TopBar';
import Footer from './Footer/Footer';
import './App.css';
import {Outlet } from 'react-router-dom'


function App() {

  return (
	  <div className="App">
		<TopBar />
			<Outlet />  
		<Footer />

	  </div>
  );
}


export default App;
