import React from 'react'; 
import './TopBarStyle.css';
import {Link} from "react-router-dom";

function TopBar(){
	return (
		<div className="TopBar">
			<Link to="/" className="Logo" ><h1 >Bud Cinemas</h1></Link>
		</div>
	)
}

export default TopBar;